import { useQuery } from "@tanstack/react-query"
import { isBrowser } from "utils";
import { fetcher } from "../utils";

export function getUrl() {
  const prefixUrl = new URL(
    isBrowser ? '/api/licenseplate/proxy' : '/api',
    isBrowser ? window.location.origin : process.env.API_URL
  );

  return prefixUrl;
}


export interface IVehicleInfo {
  chassis: string;
  chassisNr: string;
  displayLine1: string;
  displayLine2: string;
  driveShaft: string;
  engineCode: string;
  found: boolean;
  fuelType: string;
  horsepower: string;
  kType: string;
  licensePlate: string;
  manufacturer: string;
  modelSeries: string;
  productionYear: string;
  vehicleTypeNumber: number;
  firstRegisteredDate: string;
}

export const useSearchVehicleByLicense = (regNr: string) => {

  const url = getUrl()

  return useQuery({
    queryKey: ['licensePlate', regNr],
    enabled: !!regNr,
    cacheTime: Infinity,
    queryFn: () => fetcher<IVehicleInfo>({ endpoint: `${url}/licenseplate/${regNr}` })
      .then((res) => res.json()),
  })
}