import React, { FC, FormEventHandler, ChangeEventHandler, MouseEventHandler } from 'react'
import styles from './LicensePlateSearchForm.module.scss'
import { Button, Text } from 'ui'

interface IProps {
    onSubmit: FormEventHandler<HTMLFormElement>;
    onChange: ChangeEventHandler<HTMLInputElement>
    onButtonClick: MouseEventHandler<HTMLButtonElement>
    value: string;
    formIsDirty: boolean;
    children?: React.ReactNode;
}

const LicensePlateSearchForm: FC<IProps> = (props) => {
    const { onSubmit, value, onChange, formIsDirty, onButtonClick } = props

    return (
        <form onSubmit={onSubmit}>
            <div className={styles.inputWrapper}>
                <Text className={styles.countryCode} color='neutralWhite'>DK</Text>
                <input
                    className={styles.input}
                    value={value}
                    onChange={onChange} />
                {value ? (
                    <Button
                        className={styles.submitButton}
                        iconName={formIsDirty ? 'arrow-right' : 'close'}
                        variant='link'
                        type={formIsDirty ? 'submit' : 'button'}
                        onClick={onButtonClick}
                    />
                ) : <div className={styles.buttonPlaceholder} />}
            </div>
        </form>
    )
}

export default LicensePlateSearchForm