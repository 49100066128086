type Fetch<T> = Omit<Response, 'json'> & {
    json: () => Promise<T>;
};

type ParamType = {
    [key: string]: string | string[] | undefined;
};

type FetcherType = {
    endpoint: string;
    params?: ParamType;
    headers?: HeadersInit;
    method?: 'GET' | 'POST' | 'DELETE';
    body?: string;
};

/**
 * Helper method for fetching content from the API
 */
export async function fetcher<T>({
    endpoint,
    params = {},
    headers = {},
    method = 'GET',
    body,
}: FetcherType): Promise<Fetch<T>> {
    // Build url
    const url = new URL(endpoint);

    // Add query params
    Object.entries(params).forEach(([key, value]) => {
        url.searchParams.append(key, String(value));
    });

    console.info('Querying', url.href);

    const response = await fetch(url.href, {
        method: method,
        headers: {
            ...headers,
            'Content-Type': 'application/json; charset=utf-8',
        },
        keepalive: true,
        body: body,
    });

    const { status, statusText } = response;

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response;
}
