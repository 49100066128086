import { CmsModel } from 'api-types';
import { FC, FormEvent, useState } from 'react';
import { ModuleContainer, Text, RawHtml } from 'ui';
import LicensePlateSearchForm from '~/components/shared/LicensePlateSearchForm/LicensePlateSearchForm';
import { useRouter } from 'next/router';
import { useFrame } from '~/services/cms';
import { LICENSE_PLATE_SEARCH_QUERY } from '~/components/features/LicensePlateSearch/LicensePlateSearch';
import styles from './M171VehicleSearch.module.css'


export const M171VehicleSearch: FC<CmsModel.M171VehicleSearch> = (props) => {
    const { title, description } = props

    const { data: frameData } = useFrame();
    const staticLinks = frameData?.staticLinks;
    const { push } = useRouter()

    const [inputValue, setInputValue] = useState('')
    const canSubmit = inputValue?.length > 3

    const redirectToSearchPage = () => {
        if (!canSubmit) return
        push({
            pathname: staticLinks?.searchPage?.url,
            query: { [LICENSE_PLATE_SEARCH_QUERY]: inputValue },
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        redirectToSearchPage()
    }

    return (
        <ModuleContainer hasGutter>
            <div className={styles.root}>
                <div className={styles.formWrapper}>
                    <LicensePlateSearchForm
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onSubmit={handleSubmit}
                        formIsDirty={canSubmit}
                        onButtonClick={() => {
                            canSubmit ? redirectToSearchPage() : setInputValue('')
                        }}
                    />
                </div>
                <div>
                    <Text text={title} variant='display3' />
                    <RawHtml html={description} />
                </div>
            </div>
        </ModuleContainer>
    )
}