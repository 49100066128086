import React, { FC, FormEvent, useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use';
import { useSearchVehicleByLicense, IVehicleInfo } from '../ProductsList/hooks/use-search-vehicle-by-license-plate';
import styles from './LicensePlateSearch.module.css'
import VehicleInfo from './VehicleInfo/VehicleInfo';
import { useRouter } from 'next/router';
import { format } from 'url'
import LicensePlateSearchForm from '~/components/shared/LicensePlateSearchForm/LicensePlateSearchForm';
import { ActiveFacets } from '../ProductsList/Facets/ActiveFacets/ActiveFacets';
import { IFacetsProps } from '../ProductsList/Facets/Facets';
export const LICENSE_PLATE_SEARCH_QUERY = 'license-plate-search'
export const LOCAL_STORAGE_VEHICLE_KEY = 'jpGroup-selectedVehicle'


interface IProps extends IFacetsProps {
    setSelectedVehicleTypeNumber: React.Dispatch<React.SetStateAction<number | null>>
};

const LicensePlateSearch: FC<IProps> = ({ setSelectedVehicleTypeNumber, ...props }) => {

    const { query, replace } = useRouter()

    const [
        localStorageVehicle, setLocalStorageVehicle
    ] = useLocalStorage<IVehicleInfo | null>(LOCAL_STORAGE_VEHICLE_KEY, undefined)

    const [formIsDirty, setFormIsDirty] = useState(false)
    const [inputVal, setInputVal] = useState<string>(localStorageVehicle?.licensePlate || '')
    const [regNo, setRegNo] = useState<string>('')


    const {
        data: vehicleData,
        isError,
        isFetching,
    } = useSearchVehicleByLicense(regNo)


    const handleClearVehicle = () => {
        if (!localStorageVehicle || formIsDirty) return
        setLocalStorageVehicle(null)
        setInputVal('')
        setRegNo('')
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setRegNo(inputVal)
        setFormIsDirty(false)
    }

    function removeLicensePlateSearch() {
        const copiedRouterParams = { ...query }
        delete copiedRouterParams.slug
        delete copiedRouterParams[LICENSE_PLATE_SEARCH_QUERY]

        const hasHash = window.location.hash && window.location.hash.length > 0;

        const url = hasHash ? format({
            pathname: window.location.pathname,
            query: copiedRouterParams,
            hash: window.location.hash
        }) : format({
            pathname: window.location.pathname,
            query: copiedRouterParams,
        })

        replace(
            url,
            undefined,
            { shallow: true, scroll: false }
        )
    }


    // Keep for future use!!

    // const setVehicleFacets = async () => {
    //     if (!vehicleData) return
    //     setMultipleFacets([
    //         {
    //             facetKey: 'Manufacturer',
    //             facetValue: manufacturer
    //         },
    //         {
    //             facetKey: 'ModelSeries',
    //             facetValue: modelSeries
    //         },
    //         {
    //             facetKey: 'VehicleType',
    //             facetValue: vehicleType
    //         },
    //     ].filter(f => f.facetValue))
    // }

    // If LICENSE_PLATE_SEARCH_QUERY is present, search for vehicle and remove the query param
    useEffect(() => {
        if (query?.[LICENSE_PLATE_SEARCH_QUERY]) {
            const val = (query?.[LICENSE_PLATE_SEARCH_QUERY] || '') as string
            if (val.length > 3) {
                setInputVal(val)
                setRegNo(val)
            }
            removeLicensePlateSearch()
        }
    }, [query])

    useEffect(() => {
        if (!localStorageVehicle) {
            setSelectedVehicleTypeNumber(null)
        }

        if (localStorageVehicle?.found) {
            setSelectedVehicleTypeNumber(localStorageVehicle?.vehicleTypeNumber)
        }

        return () => {
            setSelectedVehicleTypeNumber(null)
        }

    }, [localStorageVehicle])

    useEffect(() => {
        if (vehicleData) {
            setLocalStorageVehicle(vehicleData)
        }
        if (isError) {
            setLocalStorageVehicle(null)
        }

    }, [vehicleData, isError])


    return (
        <div className={styles.root}>
            <LicensePlateSearchForm
                onSubmit={handleSubmit}
                value={inputVal}
                onChange={(e) => {
                    setInputVal(e.target.value)
                    setFormIsDirty(true)
                }}
                onButtonClick={handleClearVehicle}
                formIsDirty={formIsDirty}
            />
            <VehicleInfo
                vehicle={localStorageVehicle}
                isLoading={isFetching}
                isError={isError}
            />
            <ActiveFacets {...props} />
        </div>
    )
}

export default LicensePlateSearch