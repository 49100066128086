import React, { FC } from 'react'
import { Loader, Text } from 'ui';
import { IVehicleInfo } from '../../ProductsList/hooks/use-search-vehicle-by-license-plate';
import styles from './VehicleInfo.module.css'
import { useTranslations } from '~/services/cms';
import ErrorBox from '~/components/shared/ErrorBox/ErrorBox';

interface IProps {
    isLoading: boolean;
    isError: boolean;
    vehicle?: IVehicleInfo | null;
}

const VehicleInfo: FC<IProps> = ({ vehicle, isLoading, isError }) => {

    const translate = useTranslations();

    if (isLoading) return (
        <Loader
            size='35px'
            padding='0'
            width='100px'
            borderWidth='3px' />
    )

    if (isError) return (
        <ErrorBox />
    )

    if (!vehicle) {
        return null
    }

    if (!vehicle?.found) {
        return (
            <Text color='attentionColor'>
                {translate('common.informationNotFound')}
            </Text>
        )
    }

    return (
        <div className={styles.root}>
            <div>
                <Text >
                    {vehicle?.displayLine1}
                </Text>
                <Text
                    variant='bodyExtraSmall'
                    color='natural700Color'>
                    {vehicle?.displayLine2}
                </Text>
            </div>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <Text variant='bodyExtraSmall' className={styles.listLabel}>
                                {translate('vehicle.vin')}
                            </Text>
                        </td>
                        <td>
                            <Text variant='bodyExtraSmall' color='natural700Color'>
                                {vehicle?.chassisNr}
                            </Text>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Text variant='bodyExtraSmall' className={styles.listLabel}>
                                {translate('vehicle.engineCode')}
                            </Text>
                        </td>
                        <td>
                            <Text variant='bodyExtraSmall' color='natural700Color'>
                                {vehicle?.engineCode}
                            </Text>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Text variant='bodyExtraSmall' className={styles.listLabel}>
                                {translate('vehicle.firstReleaseDate')}
                            </Text>
                        </td>
                        <td>
                            <Text variant='bodyExtraSmall' color='natural700Color'>
                                {vehicle?.firstRegisteredDate}
                            </Text>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default VehicleInfo